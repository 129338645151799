<template>
	<div>
		<!-- start page title -->
		<div class="row mt-4 mb-3">
			<div class="col-12">
				<div class="page-title-box">
					<h4 class="page-title">Настройки</h4>
				</div>
			</div>
		</div>

		<ul class="nav nav-pills bg-nav-pills nav-justified mb-3">
			<li class="nav-item">
				<router-link :to="{ name: 'SettingsProfile' }" active-class="active" class="nav-link rounded-0" exact tag="a">
					<i class="mdi mdi-account-circle d-md-none d-block"></i>
					<span class="d-none d-md-block">Профиль</span>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{ name: 'SettingsSecurity' }" active-class="active" class="nav-link rounded-0" exact tag="a">
					<i class="mdi mdi-security d-md-none d-block"></i>
					<span class="d-none d-md-block">Безопасность</span>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{ name: 'SettingsNotification' }" active-class="active" class="nav-link rounded-0" exact tag="a">
					<i class="mdi mdi-message-settings-variant-outline d-md-none d-block"></i>
					<span class="d-none d-md-block">Уведомления</span>
				</router-link>
			</li>
		</ul>

		<router-view class="child-view"></router-view>
	</div>
</template>

<script>
export default {
	name: 'SettingsLayout',
};
</script>
